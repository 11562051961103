<template>
    <div>
        <v-toolbar flat>
        <v-icon left>mdi-account-box-outline</v-icon>
        <v-toolbar-title>DISPOSITIVOS</v-toolbar-title>
        <v-divider class="mx-4" vertical></v-divider>
        <v-btn @click="abrirDialogDevice(null)" color="blue" small dark
            ><v-icon>mdi-plus</v-icon> Crear</v-btn
        >
        
        </v-toolbar>
        <v-data-table
        :headers="headers"
        :items-per-page="itemsPerPage"
        :items="devices"
        class="elevation-1"
        small
        
        item-key="device_id"
        hide-default-footer
        >
        <template v-slot:item.opciones="{ item }">
            <v-btn  title="Borrar dispositivo" small icon @click="borrarDevice(item.device_id)"  dark
            ><v-icon small color="red">mdi-delete</v-icon></v-btn
            >
            <v-btn title="Procesos" small icon @click="abrirDialogProcDev(item)" >
                <v-icon small>mdi-cogs</v-icon>
            </v-btn>
            <v-btn text small target="_blank" :href="`http://whatsapi.sufactura.ec:8081/login?token=${item.token}`">
            Activar
            </v-btn>
        </template>
        <template v-slot:item.device_id="{ item }">
            <v-btn title="Editar dispositivo" text small @click="editarDevice(item)">
            {{ item.device_id }}
            </v-btn>
        </template>        

        <template v-slot:footer>        
            <v-pagination
            class="mt-10"
            v-model="currentPage"
            :length="pageCount"
            @input="handlePageChange"
            total-visible="10"
            ></v-pagination>
        </template>
        </v-data-table>

        <!-- DIALOGO DISPOSITIVO -->
        <v-dialog v-model="dialogoDevice" persistent width="700">
        <v-form ref="formDevice">
        <v-card>

            <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Dispositivo
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoDevice = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
            <v-col md="12" class="py-0 my-0">
                <v-text-field
                :rules="requiredRule"
                v-model="datosDevice.device_number"
                label="Número"
                ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
                <v-text-field
                :rules="requiredRule"
                v-model="datosDevice.description"
                label="Descripción"
                ></v-text-field>
            </v-col>

            <v-col md="12" class="py-0 my-0">
                <v-text-field                
                v-model="datosDevice.token"
                label="Token"
                ></v-text-field>
            </v-col>
            </v-row>

            <v-toolbar>
            <v-btn color="primary" small @click="guardarDevice">
                <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoDevice = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
            </v-toolbar>
        </v-card>
        </v-form>
        </v-dialog>

        <!-- DIALOGO PROCESOS DISPOSITIVO -->
        <v-dialog v-model="dialogoProcDev" persistent width="700">
        <v-form ref="formProcDev">
        <v-card>
            
            <v-toolbar dark color="blue" height="40" flat>
            <v-icon left>mdi-account-box-outline</v-icon>
            Procesos
            <v-spacer></v-spacer>
            <v-btn small text @click="dialogoProcDev = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            </v-toolbar>
            <v-row class="mt-1 mx-2 pt-0 mt-0">
            
                <v-col md="12" class="py-0 my-0">
                    <v-toolbar flat>
                        <v-btn @click="agregarProceso" title="Agregar proceso" small color="green" dark ><v-icon>mdi-plus</v-icon> </v-btn>
                    </v-toolbar> 
                    <v-data-table 
                    :items="devProc"
                    :headers="headDevProc"
                    >
                    
                    <template v-slot:item.opciones="{item}">
                        <v-btn @click="cargarProceso(item.cmdp_id)" icon small color="green"><v-icon>mdi-pencil</v-icon> </v-btn>
                        <v-btn @click="borrarProceso(item.cmdp_id)" icon small color="red"><v-icon>mdi-delete</v-icon> </v-btn>
                    </template>    
                    
                    </v-data-table>
                
                </v-col>
            
            </v-row>
            <!-- <v-row>
                <v-col>
                    {{datosProceso}} {{cmdpId}}
                </v-col>
            </v-row> -->

            <v-row>
                <v-col class="mt-1 mx-4 px-2">
                    
                    <v-select 
                        label="Plantilla" 
                        v-model="datosProceso.template_id" 
                        :items="plantillas"  
                        item-text="nombre" 
                        item-value="message_template_id"
                        :rules="requiredRule"
                        ></v-select>
                </v-col>

                <v-col class="mt-1 mx-4 px-2">
                    <v-text-field 
                    :rules="requiredRule"
                    label="Nombre del proceso" 
                    v-model="datosProceso.cmdp_nombre" ></v-text-field>
                </v-col>
            </v-row>

            <v-toolbar>
            <v-btn color="primary" small @click="guardarProceso">
                <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-divider vertical class="px-2" inset></v-divider>
            <v-btn text small @click="dialogoProcDev = false">
                <v-icon>mdi-content-cancel</v-icon>Cancelar
            </v-btn>
            </v-toolbar>
        </v-card>
        </v-form>
        </v-dialog>
    </div>
    </template>

    <script>
    import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
    import Vue from 'vue';

    export default {
    name: "DispositivoCom",
    data: () => ({
        

        requiredRule: [(v) => !!v || "El campo es requerido"],
        options: {},
        setfilters: [],
        selected: [],
        users: [],
        totalItems: 1,
        currentPage: 1,
        itemsPerPage: 10,
        pageCount: 0,
        required: [(v) => !!v || "El campo es requerido"],
        headers: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "device_id" },
            { text: "Descripción", align: "start", value: "description" },
            { text: "Número", align: "start", value: "device_number" },
            { text: "Token", align: "start", value: "token" },
        ],
                
        dialogoDevice: false,
        datosDevice: {
            device_id: "",
            token: "",
            description: "",
            device_number:""
        },
        crearDevice: false,
        devices: [],
        dialogoProcDev:false,
        devProc: [],
        headDevProc: [
            { text: "Opciones", align: "start", value: "opciones" },
            { text: "ID", align: "start", value: "cmdp_id" },
            { text: "Descripcion", align: "start", value: "cmdp_nombre" },
            { text: "Plantilla", align: "start", value: "plantilla" },
            
        ],
        datosProceso: {},
        plantillas: [],
        commDevId: ''
    }),
    computed: {
        ...mapState("master", ["loadingBtn", "companies"]),
        ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),
    },
    methods: {
        ...mapMutations("master", [
        "setUrl",
        "setLoadingTable",
        "setTitleToolbar",
        "setTenantId",
        ]),

        ...mapActions("master", ["requestApi", "alertNotification"]),
        ...mapActions("access", []),

        cargarDatos() {
        this.setLoadingTable(true);

        this.setUrl("com-device");

        this.requestApi({
            method: "GET",
            data: {
            page: this.currentPage,
            page_size: this.itemsPerPage,
            paginator: true,            
            },
        })
            .then((res) => {
            //console.log(res.data);
            this.devices = res.data._embedded.com_device;
            this.pageCount = res.data.page_count;
            this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            })
            .then(() => {
            this.setLoadingTable(false);
            });
        },
        abrirDialogDevice(devId) {
        this.dialogoDevice = true;
        this.datosDevice = {
            device_id: "",
            description: "",
            token: "",
            device_number: ""
        };
        this.crearDevice = true;
        if (devId != null) {
            this.crearDevice = false;
            this.cargarDatos(devId);
        }
        },

        editarDevice(item) {
            this.dialogoDevice = true;            
            this.crearDevice = false;


            
            this.datosDevice = 
                {
                    device_id: item.device_id,
                    description: item.description,
                    token: item.token,
                    device_number: item.device_number
                };
                this.crearDevice = false;
                //this.cargarDatos(item.device_id);
            
        },

        guardarDevice() {
            if(!this.$refs.formDevice.validate()) {
                return false
            }
            this.setLoadingTable(true);
            this.setUrl("com-device");
            this.requestApi({
            method: "POST",
            data: {
                accion: this.crearDevice == true ? "crearDevice" : "modificarDevice",
                device: this.datosDevice,
            },
            })
            .then((res) => {
                console.log(res.data);
                this.cargarDatos();
                this.dialogoDataSource = false;
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        
        },

        borrarDevice(devId) {

            Vue.swal({
                    html: "Está seguro de eliminar este dispositivo ?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {
                    this.setLoadingTable(true);
                    this.setUrl("com-device");
                    this.requestApi({
                        method: "DELETE",
                        data: {
                        accion: "borrarDevice",
                        device_id: devId, 
                        },
                    })
                    .then((res) => {                            
                        this.cargarDatos();
                        this.dialogoDevice = false;
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
                }
            });
        },

        handlePageChange(value) {
            this.cargarDatos();
        },

        crearProceso() {
            if(!this.$refs.formProcDev.validate()) {
                return false
            }
            this.setUrl("com-device");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "crearProceso",
                    datosProceso: this.datosProceso
                },
            })
            .then((res) => {                
                this.cargarProcDev({device_id:this.commDevId})
                this.datosProceso={}                
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        },

        modificarProceso() {
            if(!this.$refs.formProcDev.validate()) {
                return false
            }
            this.setUrl("com-device");
            this.requestApi({
                method: "POST",
                data: {
                    accion: "modificarProceso",
                    datosProceso: this.datosProceso
                },
            })
            .then((res) => {                
                this.cargarProcDev({device_id:this.commDevId})
                this.datosProceso={}                
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        },

        guardarProceso() {
            
            if( this.datosProceso.cmdp_id == "" || this.datosProceso.cmdp_id == undefined) {
                
                this.crearProceso()
            } else {
                
                this.modificarProceso()
            }
        },

        abrirDialogProcDev(item) {
            this.dialogoProcDev=true
            this.cargarProcDev(item)
        },

        cargarProcDev(item) {
            this.setUrl("com-device");
            this.requestApi({
                method: "POST",
                data: {
                accion: "cargarProcesosDisp",
                device_id: item.device_id, 
                },
            })
            .then((res) => {                
                this.devProc = res.data.detail.procesos
                this.plantillas = res.data.detail.plantillas
                this.commDevId = item.device_id
                this.datosProceso = {comm_dev_id : item.device_id}
                this.dialogoDevice = false;
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        },

        cargarProceso(pId) {
            this.setUrl("com-device");
            this.requestApi({
                method: "POST",
                data: {
                accion: "cargarProcesoDisp",
                cmdp_id: pId,
                },
            })
            .then((res) => {                                    
                this.datosProceso = res.data.detail.proceso            
            })
            .then(() => {
                this.setLoadingTable(false);
            });
        },

        agregarProceso() {
            this.datosProceso = {comm_dev_id:this.commDevId}
        },

        borrarProceso(cmdpId) {
            Vue.swal({
                html: "Está seguro de eliminar este proceso ? ",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

                    this.setUrl("com-device");
                    this.requestApi({
                        method: "POST",
                        data: {
                        accion: "borrarProceso",
                        cmdp_id: cmdpId, 
                        },
                    })
                    .then((res) => {   
                        
                        this.cargarProcDev({device_id: this.commDevId})  
                        this.datosProceso = {comm_dev_id : item.device_id}                    
                    })
                    .then(() => {
                        this.setLoadingTable(false);
                    });
                }
            });
        }
    },
    mounted() {
        this.cargarDatos();
        this.setTitleToolbar("DISPOSITIVOS");
    },
    };
    </script>
